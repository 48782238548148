import { types, flow } from "mobx-state-tree";
import { User } from "../models/Usuarios/User";
import { Socio } from "../models/Socios/Socio";
import { api } from "../api";
import { ActividadSocio } from "../models/Actividades/ActividadSocio";
import { TipoMetodoPago } from "../models/Pagos/TipoMetodoPago";
import { GeneroSocio } from "../models/Socios/GeneroSocio";
import { Banco } from "../models/Pagos/Banco";
import { TipoSocio } from "../models/Socios/TipoSocio";
import { Grupo } from "../models/Socios/Grupo";
import { Enfermedad } from "../models/Socios/Enfermedad";
import { Provincia } from "../models/Socios/Provincia";
import { Localidad } from "../models/Socios/Localidad";
import { Pais } from "../models/Socios/Pais";
import { RazonPago } from "../models/Pagos/RazonPago";
import { EstadoPago } from "../models/Pagos/EstadoPago";
import { SocioListSettings } from "../models/Socios/SocioListSettings";
import { Pago } from "../models/Pagos/Pago";
import { PagoListSettings } from "../models/Pagos/PagoListSettings";
import { ConfiguracionMensajeria } from "../models/Socios/ConfiguracionMensajeria";
import { TipoMensaje } from "../models/Socios/TipoMensaje";
import { TipoSuscripcion } from "../models/Pagos/TipoSuscripcion";
import { SocioArchivoAdjuntoCategoria } from "../models/Socios/SocioArchivoAdjuntoCategoria";
import { Movimiento } from "../models/Pagos/Movimiento";
import { MovimientoListSettings } from "../models/Pagos/MovimientoListSettings";
import { Categoria } from "../models/Categoria";
import { PreguntaFrecuente } from "../models/PreguntaFrecuente";
import { ActividadListSettings } from "../models/Actividades/ActividadListSettings";

const RootStore = types
  .model({
    user: types.maybe(User),
    socios: types.array(Socio),
    movimientos: types.array(Movimiento),
    sociosDisponiblesGrupo: types.array(Socio),
    actividades: types.array(ActividadSocio),
    tipometodopagos: types.array(TipoMetodoPago),
    razonpagos: types.array(RazonPago),
    estadopagos: types.array(EstadoPago),
    generossocio: types.array(GeneroSocio),
    pagos: types.array(Pago),
    bancos: types.array(Banco),
    tiposocios: types.array(TipoSocio),
    currentSocio: types.maybe(Socio),
    currentSocioGrupo: types.maybeNull(Grupo),
    enfermedades: types.array(Enfermedad),
    provincias: types.array(Provincia),
    localidades: types.array(Localidad),
    paises: types.array(Pais),
    responsables_mayores: types.array(Socio),
    configuracion_mensajeria: types.array(ConfiguracionMensajeria),
    socioListSettings: types.optional(SocioListSettings, {}),
    movimientoListSettings: types.optional(MovimientoListSettings, {}),
    actividadListSettings: types.optional(ActividadListSettings, {}),
    pagoListSettings: types.optional(PagoListSettings, {}),
    tipomensajes: types.array(TipoMensaje),
    categoriaadjuntos: types.array(SocioArchivoAdjuntoCategoria),
    tiposuscripciones: types.array(TipoSuscripcion),
    categoriamovimientos: types.array(Categoria),
    faq: types.array(PreguntaFrecuente),
    vader: false,
  })
  .actions((self) => {
    return {
      getLocalidades: flow(function* () {
        try {
          const result = yield api.getLocalidades();
          self.localidades = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getPreguntasFrecuentes: flow(function* () {
        try {
          const result = yield api.getPreguntasFrecuentes();
          self.faq = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getMetodosEnvio: flow(function* () {
        try {
          const result = yield api.getMetodosEnvio();
          self.configuracion_mensajeria = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getPaises: flow(function* () {
        try {
          const result = yield api.getPaises();
          self.paises = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getProvincias: flow(function* () {
        try {
          const result = yield api.getProvincias();
          self.provincias = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getCategoriaAdjuntos: flow(function* () {
        try {
          const result = yield api.getCategoriaAdjuntos();
          self.categoriaadjuntos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getCategoriaMovimientos: flow(function* () {
        try {
          const result = yield api.getCategoriaMovimientos();
          self.categoriamovimientos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocios: flow(function* () {
        try {
          const result = yield api.getSocios();
          self.socios = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getMovimientos: flow(function* () {
        try {
          const result = yield api.getMovimientos();
          self.movimientos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getBancos: flow(function* () {
        try {
          const result = yield api.getBancos();
          self.bancos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getPagos: flow(function* () {
        try {
          const result = yield api.getPagos();
          self.pagos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getEnfermedades: flow(function* () {
        try {
          const result = yield api.getEnfermedades();
          self.enfermedades = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioActividades: flow(function* () {
        try {
          const result = yield api.getSocioActividades();
          self.actividades = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSociosResponsablesMayores: flow(function* () {
        try {
          const result = yield api.getSociosResponsablesMayores();
          self.responsables_mayores = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioTipoMetodoPagos: flow(function* () {
        try {
          const result = yield api.getSocioTipoMetodoPagos();
          self.tipometodopagos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioEstadoPagos: flow(function* () {
        try {
          const result = yield api.getSocioEstadoPagos();
          self.estadopagos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioRazonPagos: flow(function* () {
        try {
          const result = yield api.getSocioRazonPagos();
          self.razonpagos = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioGeneros: flow(function* () {
        try {
          const result = yield api.getSocioGeneros();
          self.generossocio = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioTipoSocios: flow(function* () {
        try {
          const result = yield api.getSocioTipoSocios();
          self.tiposocios = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getOption: (option: string) => {
        switch (option) {
          case "genero":
            return self.generossocio;
          case "tipo_socio":
            return self.tiposocios;
          case "enfermedades":
            return self.enfermedades;
          case "localidad":
            return self.localidades;
          case "responsable_mayor":
            return self.responsables_mayores;
          default:
            return [];
        }
      },
      setCurrentSocio: flow(function* (socioid: string) {
        try {
          const result = yield api.getSocio(socioid);
          self.currentSocio = result.data;
          const grupo = yield api.getSocioGrupo(socioid);
          self.currentSocioGrupo = grupo.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      refreshCurrentSocio: flow(function* () {
        try {
          if (self.currentSocio) {
            const result = yield api.getSocio(self.currentSocio.id + "");
            self.currentSocio = result.data;
            self.vader = !self.vader;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      refreshCurrentSocioGrupo: flow(function* () {
        try {
          if (self.currentSocio) {
            const result = yield api.getSocioGrupo(self.currentSocio.id + "");
            self.currentSocioGrupo = result.data;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getCurrentSocioGrupo: flow(function* () {
        try {
          if (self.currentSocio) {
            const result = yield api.getSocioGrupo(self.currentSocio.id + "");
            if (result.data === "") {
              self.currentSocioGrupo = null;
            }
            self.currentSocioGrupo = result.data;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      exportDocument: flow(function* (response: any) {
        try {
          const result = yield api.exportDocument(response);
          return result;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSociosDisponiblesGrupo: flow(function* (grupoid: any) {
        try {
          const result = yield api.getSociosDisponiblesGrupo(grupoid);
          self.sociosDisponiblesGrupo = result.data;
          return result;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getTipoSuscripciones: flow(function* () {
        try {
          const result = yield api.getTipoSuscripciones();
          self.tiposuscripciones = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getTipoMensaje: flow(function* () {
        try {
          const result = yield api.getTipoMensaje();
          self.tipomensajes = result.data;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
    };
  });
export default RootStore;
