import { types } from "mobx-state-tree";
import { Banco } from "./Banco";  
import { TipoMetodoPago } from "./TipoMetodoPago";

export const MetodoPago = types.model("MetodoPago", {
  id: 0,
  datos: "",
  banco: types.maybeNull(Banco),
  tipo_metodo_pago: types.maybe(TipoMetodoPago), 
});
