import { types } from "mobx-state-tree";
import { TipoMetodoPago } from "./TipoMetodoPago";
import { RazonPago } from "./RazonPago";
import { Socio } from "../Socios/Socio"; 
import { EstadoPago } from "./EstadoPago";

export const Pago = types.model("Pago", {
  id: 0,
  socio: types.maybeNull(Socio),
  monto: types.union(types.string, types.number),
  razon: types.maybeNull(RazonPago),
  tipo_metodo_pago: types.maybeNull(TipoMetodoPago),
  fecha_pago: "",
  razon_otro: "",
  observaciones: "",
  adjunto: types.maybeNull(types.string),
  estado: types.maybeNull(EstadoPago),
  id_pago_ml: 0,
});
