import { applySnapshot, Instance, types } from "mobx-state-tree";
import { TableColumn } from "./../TableColumn";

export const PagoListSettings = types
  .model("PagoListSettings", {
    rowsPerPage: 10,
    columns: types.array(TableColumn),
  })
  .actions((self) => { 
    return {
      showColumn: (columnName: string) => {
        for (let c of self.columns) {
          if (c.name === columnName) return c.display;
        }
        if (
          columnName === "monto" ||
          columnName === "socio" ||
          columnName === "fecha_pago"
        )
          return true;
        return false;
      },
      update: (data: IPagoListSettings) => {
        applySnapshot(self, {
          rowsPerPage: data.rowsPerPage,
          columns: data.columns,
        });
        return true;
      },
      updateRowsPerPage: (rowsPerPage: number) => {
        self.rowsPerPage = rowsPerPage;
        return true;
      },
    };
  });
export interface IPagoListSettings extends Instance<typeof PagoListSettings> {}
