import { types } from "mobx-state-tree";
import { ActividadCategoria } from "./ActividadCategoria";
import { ActividadGenero } from "./ActividadGenero";
import { ActividadSocioNombre } from "./ActividadSocioNombre";
import { EncargadoActividad } from "./EncargadoActividad";
import { ScheduleActividad } from "./ScheduleActividad";
import { VaderSocio } from "../Socios/VaderSocio";

export const ActividadSocio = types.model("ActividadSocio", {
  id: 0,
  asistencia: types.maybeNull(types.number),
  cantidad_integrantes: types.maybeNull(types.number),
  foto: types.maybeNull(types.string),
  ultima_asistencia: types.maybeNull(types.string),
  fecha_inicio: types.maybeNull(types.string),
  schedule: types.array(ScheduleActividad),
  socios: types.array(VaderSocio),
  creado_en: types.maybeNull(types.string),
  modificado_en: types.maybeNull(types.string),
  iu: "",
  nombre: types.maybeNull(ActividadSocioNombre),
  encargado: types.maybeNull(EncargadoActividad),
  genero: types.maybeNull(ActividadGenero),
  categoria: types.maybeNull(ActividadCategoria),
});
