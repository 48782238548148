import { types } from "mobx-state-tree";
import { Categoria } from "./../Categoria";

export const Movimiento = types.model("Movimiento", {
  id: 0,
  tipo: 0,
  monto: types.maybeNull(types.string),
  adjunto: types.maybeNull(types.string),
  pago: types.maybeNull(types.number),
  observaciones: types.maybeNull(types.string),
  categoria: types.maybeNull(Categoria),
  fecha: types.maybeNull(types.string),
});
