import { applySnapshot, Instance, types } from "mobx-state-tree";
import { TableColumn } from "./../TableColumn";

export const MovimientoListSettings = types
  .model("MovimientoListSettings", {
    rowsPerPage: 15,
    columns: types.array(TableColumn),
  })
  .actions((self) => {
    return {
      showColumn: (columnName: string) => {
        for (let c of self.columns) {
          if (c.name === columnName) return c.display;
        }
        if (
          columnName === "tipo" ||
          columnName === "monto" ||
          columnName === "categoria"
        )
          return true;
        return false;
      },
      update: (data: IMovimientoListSettings) => {
        applySnapshot(self, {
          rowsPerPage: data.rowsPerPage,
          columns: data.columns,
        });
        return true;
      },
      updateRowsPerPage: (rowsPerPage: number) => {
        self.rowsPerPage = rowsPerPage;
        return true;
      },
    };
  });
export interface IMovimientoListSettings
  extends Instance<typeof MovimientoListSettings> {}
