import { Instance, types } from "mobx-state-tree";
import { TipoAutorizacionSocio } from "./TipoAutorizacionSocio";

export const AutorizacionSocio = types.model("AutorizacionSocio", {
  id: 0,
  tipo_autorizacion: types.maybeNull(TipoAutorizacionSocio),
  adjunto: types.maybeNull(types.string),
  autoriza: "",
});
export interface IAutorizacionSocio
  extends Instance<typeof AutorizacionSocio> {}
