const AppConfig = {
  API_BASE_URL: process.env.REACT_APP_BASE_URL,
  DATE_FORMAT: "dd/MM/yyyy",
  DATOS_FUNDAMENTALES_NUTRICION: [
    "peso",
    "altura",
    "imc",
    "circunferencia_cintura",
    "cadera",
  ],
  DATOS_SECCION_CLINICA_NUTRICION: ["historia_clinica", "enfermedades"],
  DATOS_LABORATORIO_NUTRICION: [
    "colesterol_hdl",
    "colesterol_ldl",
    "colesterol_total",
    "creatinina",
    "glucemia",
    "hemoglobina",
    "hepatograma_gdp",
    "hepatograma_got",
    "insulina_plasmatica",
    "t4l",
    "trigliceridos",
    "tsh",
    "uremia",
    "uricemia",
  ],
  APP_LOGO_URL: process.env.REACT_APP_LOGO_URL,
  DATATABLE_LABELS: {
    pagination: {
      next: "Proxima",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de", // 1-10 of 30
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Mostrar Columnas",
      filterTable: "Filtrar Tabla",
    },
    filter: {
      title: "FILTROS",
      reset: "Borrar",
    },
    viewColumns: {
      title: "Mostrar Columnas",
    },
    selectedRows: {
      text: "fila(s) seleccionadas",
      delete: "Borrar",
    },
    body: {
      noMatch: "No se encontraron registros",
      tooltip: "Ordenar",
      columnHeaderTooltip: (column: any) => `Ordenar por ${column.label}`,
    },
  } as any,
  PERMISO_PSICOLOGIA: process.env.REACT_APP_PERMISO_PSICOLOGIA,
  PERMISO_NUTRICION: process.env.REACT_APP_PERMISO_NUTRICION,
};

export default AppConfig;
