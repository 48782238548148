import { createTheme } from "@material-ui/core";
import { createTheme as muiCreateTheme } from "@mui/material/styles";

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=f22233&secondary.color=173f73
// colores para el theme sacados de la página elfronton.club

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff635d",
      main: "#f22233",
      dark: "#b7000c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#4c69a2",
      main: "#173f73",
      dark: "#001a47",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
      disabled: "rgba(231, 227, 252, 0.38)",
    },
    info: {
      main: "#081225",
      dark: "#081225",
      light: "#A62934",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiBreadcrumbs: {
      // Name of the rule
      ol: {
        "& a": {
          textDecoration: "none",
          color: "#f22233",
          display: "flex",
        },
        "& .MuiSvgIcon-root": {
          color: "#9E69FD",
          width: "24px",
          height: "24px",
          marginRight: "5px",
        },
        "& .MuiTypography-root": {
          display: "flex",
          height: "24px",
        },
      },
      root: {
        paddingTop: "10px",
        paddingBottom: "10px",
        // Some CSS
      },
    },

    MuiAppBar: {
      root: {
        zIndex: "auto",
      },
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

theme.overrides = {
  ...theme.overrides,
  //color toolbar + titulo + sombra tabla
  // MUIDataTable: {
  //   paper: {
  //     width: "100%",
  //     boxShadow: "none",
  //     "& .MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar": {
  //       backgroundColor: theme.palette.info.dark + "!important",
  //     },
  //     "& .MuiPaper-root": {
  //       backgroundColor: theme.palette.info.dark /* + "!important"*/,
  //     },
  //     // "& div": {
  //     //   overflow: "hidden",
  //     // },
  //   },
  //   caption: {
  //     color: theme.palette.secondary.contrastText,
  //   },
  // },
  MuiToolbar: {
    gutters: {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  /*
  MuiMenuItem: {
    root: {
      color: theme.palette.secondary.dark,
    },
  },
  */
  //color flechita footer
  MuiSelect: {
    icon: {
      color: theme.palette.secondary.contrastText,
    },
    selectMenu: {
      color: theme.palette.secondary.contrastText,
    },
  },
  //ajuste en la lupita
  // MUIDataTableFilterList: {
  //   root: {
  //     backgroundColor: theme.palette.secondary.dark + "!important",
  //     paddingLeft: "16px",
  //     paddingRight: "16px",
  //     margin: "0px",
  //   },
  // },
  //mostrar/esconder columnas
  // MUIDataTableViewCol: {
  //   root: {
  //     backgroundColor: theme.palette.info.dark,
  //   },
  //   label: {
  //     color: theme.palette.secondary.contrastText,
  //   },
  //   title: {
  //     color: theme.palette.secondary.contrastText,
  //   },
  // },
  //campo de busqueda
  // MUIDataTableSearch: {
  //   searchIcon: {
  //     color: theme.palette.secondary.contrastText,
  //   },
  // },
  //filtros
  // MUIDataTableFilter: {
  //   root: {
  //     backgroundColor: theme.palette.info.dark,
  //   },
  //   title: {
  //     color: theme.palette.secondary.contrastText,
  //   },
  // },
  //campos de texto
  MuiInput: {
    root: {
      "&.Mui-focused": {
        color: theme.palette.secondary.contrastText,
      },
      color: theme.palette.secondary.contrastText,
    },
  },
  //labels
  MuiFormLabel: {
    root: {
      color: theme.palette.secondary.contrastText,
    },
  },
  //cuando seleccionas todo con el select all
  MuiTableBody: {
    root: {
      backgroundColor: theme.palette.info.light,
    },
  },

  MuiTableCell: {
    //header
    root: {
      padding: theme.spacing(0.375, 1.25, 0.375, 1.25),
    },
    head: {
      backgroundColor: theme.palette.info.dark + "!important",
      borderRight: "1px solid white",
    },
  },

  // handles row hover color and selected row color
  MuiTableRow: {
    root: {
      border:
        "2px solid " + theme.palette.secondary.contrastText + " !important",
      cursor: "pointer",
      "&:nth-child(odd)": {
        backgroundColor: theme.palette.info.dark,
      },
      "&:nth-child(even)": {
        backgroundColor: theme.palette.info.dark,
      },
    },
  },
  //lista de filas por pagina
  MuiList: {
    root: {
      color: theme.palette.info.dark,
    },
  },
  // handles table data header color
  // MUIDataTableHeadCell: {
  //   sortAction: {
  //     "& path": {
  //       color: theme.palette.secondary.contrastText,
  //     },
  //   },
  //   sortActive: {
  //     color: theme.palette.secondary.contrastText,
  //   },
  //   data: {
  //     color: theme.palette.secondary.contrastText,
  //   },
  // },

  MuiTablePagination: {
    root: {
      "& div": {
        backgroundColor: theme.palette.info.dark,
      },
    },
  },

  //color iconos toolbar
  MuiIconButton: {
    root: {
      color: theme.palette.secondary.contrastText + "!important",
    },
  },

  MuiTableFooter: {
    root: {
      background: "yellow !important",
      "&:hover": {
        backgroundColor: "green !important",
      },
    },
  },
};
export const profilePanelTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      //header
      root: {
        padding: theme.spacing(0.375, 1.25, 0.375, 1.25),
      },
      head: {
        borderRight: "1px solid red",
        borderTop: "1px solid blue",
        borderBottom: "1px solid yellow",
      },
    },
  },
});

export const profilePanelTableThemeOverride = muiCreateTheme({
  palette: {
    background: {
      default: "#28243D",
      paper: "#312D4B",
    },
    divider: "rgba(231, 227, 252, 0.12)",
    primary: {
      main: "#312D4B",
      contrastText: "#9155FD",
      light: "rgba(145, 85, 253, 0.12)",
    },
    secondary: {
      //main: "rgb(145, 85, 253)",
      main: "#9155FD",
      light: "#804BDF",
    },
    success: {
      light: "#6AD01F",
      main: "#56CA00",
      dark: "#4CB200",
      contrastText: "#FFF",
    },
    error: {
      light: "#FF6166",
      main: "#FF4C51",
      dark: "#E04347",
      contrastText: "#FFF",
    },
    warning: {
      light: "#FFCA64",
      main: "#FFB400",
      dark: "#E09E00",
      contrastText: "#FFF",
    },
    info: {
      light: "#32BAFF",
      main: "#16B1FF",
      dark: "#139CE0",
      contrastText: "#FFF",
    },
    text: {
      disabled: "rgba(231, 227, 252, 0.38)",
      primary: "rgba(231, 227, 252, 0.87)",
      secondary: "rgba(231, 227, 252, 0.68)",
    },
    mode: "dark",
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        // Name of the rule
        root: {
          paddingTop: "10px",
          paddingBottom: "10px",
          // Some CSS
        },
        ol: {
          "& a": {
            textDecoration: "none",
            color: "#f22233",
            display: "flex",
          },
          "& .MuiSvgIcon-root": {
            color: "#9E69FD",
            width: "24px",
            height: "24px",
            marginRight: "5px",
          },
          "& .MuiTypography-root": {
            display: "flex",
            height: "24px",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          color: "rgba(231, 227, 252, 0.87)",
          borderRadius: "4px",
          marginBottom: "20px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "rgba(231, 227, 252, 0.87)",
          "&.Mui-checked": {
            color: "#9155FD",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: "rgba(231, 227, 252, 0.87)",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#9155FD",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          backgroundColor: "#fff",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#9155FD",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#312d4b",
          backgroundImage: "none",
          //border: "1px solid rgba(231, 227, 252, 0.12)",
          boxShadow: "none",
          color: "rgba(231, 227, 252, 0.87)",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#312d4b",
          color: "rgba(231, 227, 252, 0.87)",
          "& .MuiTypography-root": {
            color: "rgba(231, 227, 252, 0.87)",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#9155FD",
          "&.Mui-checked": {
            color: "#9155FD",
          },
        },
      },
    },
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#312d4b",
    //       color: "rgba(231, 227, 252, 0.87)",
    //       borderBottom: 0,
    //     },
    //   },
    // },
    // MuiTableBody: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#312d4b",
    //     },
    //   },
    // },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(231, 227, 252, 0.12)",
        },
        checkboxRoot: {
          color: "rgba(231, 227, 252, 0.68)",
          ":hover": {
            backgroundColor: "rgba(145, 85, 253, 0.08)",
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: "rgba(231, 227, 252, 0.87)",
          borderBottom: "1px solid rgba(231, 227, 252, 0.12)",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(231, 227, 252, 0.12)",
          color: "rgba(231, 227, 252, 0.87)",
          "> div": {
            border: 0,
          },
        },
      },
    },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#312d4b",
    //       borderBottom: "1px solid rgba(231, 227, 252, 0.12)",
    //       color: "rgba(231, 227, 252, 0.87)",
    //       ":hover": {
    //         cursor: "pointer",
    //       },
    //     },
    //     head: {
    //       backgroundColor: "#3d3759 !important",
    //     },
    //   },
    // },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          fill: "rgba(231, 227, 252, 0.87)",
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          backgroundColor: "#312d4b",
          "& .mui-row-selected": {
            backgroundColor: "rgba(145, 85, 253, 0.16) !important",
            ":hover": {
              backgroundColor: "rgba(145, 85, 253, 0.24) !important",
            },
          },
        },
        hover: {
          cursor: "pointer",
          ":hover": {
            backgroundColor: "rgba(231, 227, 252, 0.04) !important",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(145, 85, 253, 0.16) !important",
            ":hover": {
              backgroundColor: "rgba(145, 85, 253, 0.24) !important",
            },
          },
        },
      },
    },
    // MuiToolbar: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#312d4b",
    //       color: "rgba(231, 227, 252, 0.87)",
    //       borderTopLeftRadius: "6px",
    //       borderTopRightRadius: "6px",
    //       backgroundImage: "none",
    //       overflow: "hidden",
    //       boxShadow: "none",
    //     },
    //   },
    // },
    MUIDataTablePagination: {
      styleOverrides: {
        toolbar: {
          backgroundColor: "#312d4b",
          color: "rgba(231, 227, 252, 0.87)",
        },
        tableCellContainer: {
          border: 0,
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          color: "rgba(231, 227, 252, 0.87)",
          borderRadius: "4px",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#312d4b",
          color: "rgba(231, 227, 252, 0.87)",
          borderBottom: 0,
        },
      },
    },
    // MuiTableBody: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#312d4b",
    //     },
    //   },
    // },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#3d3759 !important",
          color: "rgba(231, 227, 252, 0.87)",
          ":hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#312d4b",
          color: "rgba(231, 227, 252, 0.87)",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          backgroundImage: "none",
          overflow: "hidden",
          boxShadow: "none",
        },
        /*
        filterPaper: {
          top: "260px !important",
          left: "26px !important",
          maxWidth: "94.7%",
          overflowY: "scroll",
        },*/
      },
    },
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          backgroundColor: "#312d4b",
          color: "rgba(231, 227, 252, 0.87)",
        },
        gridListTile: {
          color: "red !important",
        },
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: [
      "Inter",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

export const profilePanelPagosTableTheme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        cursor: "pointer",
      },
    },
    MuiTableCell: {
      //header
      root: {
        padding: theme.spacing(0.375, 1.25, 0.375, 1.25),
      },
      head: {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
      },
    },
  },
});

export default theme;
