import { flow, types, Instance } from "mobx-state-tree";
import { api } from "../../api";
import { ActividadSocio } from "../Actividades/ActividadSocio";
import { AutorizacionSocio } from "./AutorizacionSocio";
import { GeneroSocio } from "./GeneroSocio";
import { Localidad } from "./Localidad";
import { MetodoPago } from "../Pagos/MetodoPago";
import { Nutricion } from "./Nutricion";
import { PagoSocio } from "../Pagos/PagoSocio";
import { Pais } from "./Pais";
import { Provincia } from "./Provincia";
import { Psicologia } from "./Psicologia";
import { ResponsableMayor } from "./ResponsableMayor";
import { TipoAutorizacionSocio } from "./TipoAutorizacionSocio";
import { TipoSocio } from "./TipoSocio";
import { TipoEstadoSocio } from "./TipoEstadoSocio";
import { Suscripcion } from "../Pagos/Suscripcion";
import { SocioArchivoAdjunto } from "./SocioArchivoAdjunto";
import { VaderGrupo } from "./VaderGrupo";
import { Discapacidad } from "./Discapacidad";

export const Socio: any = types
  .model("Socio", {
    id: 0,
    nombre: types.maybeNull(types.string),
    discapacidad: types.maybeNull(Discapacidad),
    apellido: types.maybeNull(types.string),
    dni: types.maybeNull(types.number),
    numero: types.maybeNull(types.string),
    direccion: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    telefono_linea: types.maybeNull(types.number),
    telefono_celular: types.maybeNull(types.number),
    fecha_nacimiento: types.maybeNull(types.string),
    codigo_postal: types.maybeNull(types.string),
    pais: types.maybeNull(Pais),
    provincia: types.maybeNull(Provincia),
    localidad: types.maybeNull(Localidad),
    genero: types.maybeNull(GeneroSocio),
    foto: types.maybeNull(types.string),
    tipo_socio: types.maybeNull(TipoSocio),
    fecha_inscripcion: types.maybeNull(types.string),
    actividades: types.array(ActividadSocio),
    pagos: types.array(PagoSocio),
    metodos_pago: types.array(MetodoPago),
    psicologiasocio: types.maybeNull(Psicologia),
    nutricionsocio: types.maybeNull(Nutricion),
    documentacionsocio: types.array(SocioArchivoAdjunto),
    responsable_mayor: types.maybeNull(ResponsableMayor),
    autorizaciones: types.array(AutorizacionSocio),
    estado: types.maybeNull(TipoEstadoSocio),
    suscripciones: types.array(Suscripcion),
    grupo_socio: types.maybeNull(VaderGrupo),
  })
  .actions((self) => {
    return {
      patchSocio: flow(function* (field, value) {
        try {
          const result = yield api.patchSocio(self.id, field, value);
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      patchNutricion: flow(function* (field, value) {
        try {
          const result = yield api.patchNutricion(self.id, field, value);
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      patchMayor: flow(function* (field, value) {
        try {
          const result = yield api.setSocioMayorResponsable(self.id, value);
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioNutricion: flow(function* () {
        try {
          const result = yield api.getSocioNutricion(self.id);
          self.nutricionsocio = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioDocumentacion: flow(function* () {
        try {
          const result = yield api.getSocioDocumentacion(self.id);
          if (result.status === 200) {
            self.documentacionsocio = result.data;
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      setNullArchivoSocio: flow(function* (field) {
        try {
          const result = yield api.setNullArchivoSocio(field, self.id);
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      deleteSocioArchivoAdjuntos: flow(function* (adjuntos: any) {
        try {
          const result = yield api.deleteSocioArchivoAdjuntos(
            self.id,
            adjuntos
          );
          if (result.status === 204) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      deleteSocioPago: flow(function* (pagos: any) {
        try {
          const result = yield api.deleteSocioPago(self.id, pagos);
          if (result.status === 204) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      deleteSocioSuscripciones: flow(function* (suscripciones: any) {
        try {
          const result = yield api.deleteSocioSuscripciones(
            self.id,
            suscripciones
          );
          if (result.status === 204) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      deleteSocioActividad: flow(function* (actividades: any) {
        try {
          const result = yield api.deleteSocioActividad(self.id, actividades);
          if (result.status === 204) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioMetodosPago: flow(function* () {
        try {
          const result = yield api.getSocioMetodosPago(self.id);
          self.metodos_pago = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      deleteSocioMetodosPagos: flow(function* (metodosdepago: any) {
        try {
          const result = yield api.deleteSocioMetodosPagos(
            self.id,
            metodosdepago
          );
          if (result.status === 204) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed", error);
        }
      }),
      getSocioAutorizacion: (codigo: string) => {
        for (let autorizacion of self.autorizaciones) {
          if (autorizacion.tipo_autorizacion?.codigo === codigo) {
            return autorizacion;
          }
        }
        const tipo_autorizacion = TipoAutorizacionSocio.create({
          codigo: codigo,
        });
        return AutorizacionSocio.create({
          tipo_autorizacion: tipo_autorizacion,
          autoriza: "NC",
        });
      },
      getSocioSuscripciones: flow(function* () {
        try {
          const result = yield api.getSocioSuscripciones(self.id);
          self.suscripciones = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
    };
  });

export interface ISocio extends Instance<typeof Socio> {}
