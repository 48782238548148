import axios from "axios";
import AppConfig from "./config";

const instance = axios.create({
  baseURL: AppConfig.API_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

export default instance;
