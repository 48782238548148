import { types } from "mobx-state-tree";
import { TipoSuscripcion } from "./TipoSuscripcion";
import { TipoMensaje } from "../Socios/TipoMensaje";

export const Suscripcion = types.model("Suscripcion", {
  id: 0,
  tipo: types.maybe(TipoSuscripcion),
  ultima_generacion: types.union(types.string, types.null),
  mensajeria_preferida: types.maybeNull(TipoMensaje),
  suscripcion_id: types.maybeNull(types.string),
  // mensajeria_preferida: types.maybe(TipoMensaje),
});
