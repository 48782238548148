import axios from "./axios";
import PaletteTransformer from "./transformers/PaletteTransformer";
import { UserTransformer } from "./transformers/UserTransformer";

import {
  LoginFormInput,
  SocioFormInput,
  SocioMetodoPagoFormInput,
  SocioAutorizacionFormInput,
  SocioSuscripcionFormInput,
  MovimientoFormInput,
  ScheduleActividadInput,
  ActividadCreateInput,
  EventInput,
  SocioActividad,
  ActividadInput,
  CalendarEvent,
} from "./types";

export const api = {
  getActividadByID: (actid: number | undefined) => {
    return axios.request({
      url: `/api/actividades/${actid}`,
      method: "GET",
    });
  },
  createActividad: (validatedFormData: ActividadCreateInput) => {
    return axios.request({
      url: `/api/actividades/actividadCreate/`,
      method: "POST",
      data: validatedFormData,
    });
  },
  getRelatedFKEventos: (tipo: string) => {
    return axios.request({
      url: `/api/eventos/eventosRelatedFK/`,
      method: "POST",
      data: { tipo: tipo },
    });
  },
  getEventos: () => {
    return axios.request({
      url: `/api/eventos/`,
      method: "GET",
    });
  },
  getCategoriasEventoTipo: (tipo: string) => {
    return axios.request({
      url: `/api/categoriaeventos/?tipo__codigo=${tipo}`,
      method: "GET",
    });
  },
  createEvento: (validatedFormData: EventInput) => {
    return axios.request({
      url: `/api/eventos/`,
      method: "POST",
      data: validatedFormData,
    });
  },
  patchEvento: (eventid: number, validatedFormData: CalendarEvent) => {
    return axios.request({
      url: `/api/eventos/${eventid}/`,
      method: "PATCH",
      data: validatedFormData,
    });
  },
  putEvento: (eventid: number, validatedFormData: CalendarEvent) => {
    return axios.request({
      url: `/api/eventos/${eventid}/patchEvento/`,
      method: "PUT",
      data: validatedFormData,
    });
  },
  createAsistenciaActividad: (actividadId: number, excluidos: number[]) => {
    return axios.request({
      url: `/api/actividades/${actividadId}/crearAsistencia/`,
      method: "POST",
      data: {
        excluidos: excluidos,
      },
    });
  },
  patchActividadByID: (
    actid: number | undefined,
    validatedFormData: ActividadInput
  ) => {
    return axios.request({
      url: `/api/actividades/${actid}/actividadEdit/`,
      method: "PATCH",
      data: validatedFormData,
    });
  },
  getActividadRelatedFields: () => {
    return axios.request({
      url: `/api/actividades/actividadGetRelatedFK`,
      method: "GET",
    });
  },
  getSociosActividad: () => {
    return axios.request({
      url: `/api/actividades/actividadCreate/`,
      method: "GET",
    });
  },
  getActividadAvailableSocios: (actid: number | undefined) => {
    return axios.request({
      url: `/api/actividades/${actid}/actividadSocio`,
      method: "GET",
    });
  },
  deleteScheduleByID: (scheduleid: number, actividadid: number | undefined) => {
    return axios.request({
      url: `/api/actividades/${actividadid}/actividadSchedule`,
      method: "DELETE",
      data: {
        schedule: scheduleid,
      },
    });
  },
  patchActividadSocio: (
    actividadid: number | undefined,
    validatedFormData: { socio: SocioActividad }
  ) => {
    return axios.request({
      url: `/api/actividades/${actividadid}/actividadSocio/`,
      method: "PATCH",
      data: validatedFormData.socio,
    });
  },
  removeActividadSocio: (
    actividadid: number | undefined,
    validatedFormData: SocioActividad
  ) => {
    return axios.request({
      url: `/api/actividades/${actividadid}/actividadSocio/`,
      method: "DELETE",
      data: validatedFormData,
    });
  },
  createSchedule: (
    actividadid: number | undefined,
    validatedFormData: ScheduleActividadInput
  ) => {
    return axios.request({
      url: `/api/actividades/${actividadid}/actividadSchedule/`,
      method: "POST",
      data: validatedFormData,
    });
  },
  createPagoML: (validatedFormData: any) => {
    return axios.request({
      url: "/api/pagos/ml",
      method: "POST",
      data: validatedFormData,
    });
  },
  getProvincias: () => {
    return axios.request({
      url: "/api/provincias/",
      method: "GET",
    });
  },
  getPalette: () => {
    return axios.request({
      url: "/api/palette/",
      method: "GET",
      transformResponse: [
        function (data) {
          return PaletteTransformer(data);
        },
      ],
    });
  },
  createPago: (validatedFormData: any) => {
    return axios.request({
      url: "/api/pagos_socio/",
      method: "POST",
      data: validatedFormData,
    });
  },
  getPagos: () => {
    return axios.request({
      url: "/api/pagos_socio/",
      method: "GET",
    });
  },
  getPagoById: (id: number | undefined) => {
    return axios.request({
      url: `/api/pagos_socio/${id}/`,
      method: "GET",
    });
  },
  getMovimientoById: (id: number | undefined) => {
    return axios.request({
      url: `/api/movimientos/${id}/`,
      method: "GET",
    });
  },
  patchPago: (pagoid: number | undefined, field: string, value: any) => {
    return axios.request({
      url: `/api/pagos_socio/${pagoid}/`,
      method: "PATCH",
      data: {
        [field]: value,
      },
    });
  },
  patchEstadoPago: (pagoid: number, field: string, value: number) => {
    return axios.request({
      url: `/api/pagos_socio/${pagoid}/patchEstado/`,
      method: "PATCH",
      data: {
        [field]: value,
      },
    });
  },
  patchPagoAdjunto: (
    pagoid: number | undefined,
    form_data: FormData,
    progressCallback: any
  ) => {
    return axios.request({
      url: `/api/pagos_socio/${pagoid}/patchEstado/`,
      method: "PATCH",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  checkSocioNumber: (value: string | undefined) => {
    return axios.request({
      url: "/api/socios/check_socio/",
      method: "POST",
      data: {
        socio_number: value,
      },
    });
  },
  getLocalidades: () => {
    return axios.request({
      url: "/api/localidades/",
      method: "GET",
    });
  },
  postPagosProductos: (data: any) => {
    return axios.request({
      url: "/api/socios/pagoProducto/",
      method: "POST",
      data: data,
    });
  },
  getProductos: () => {
    return axios.request({
      url: "/api/productos/",
      method: "GET",
    });
  },
  getProducto: (productoid: number) => {
    return axios.request({
      url: `/api/productos/${productoid}/`,
      method: "GET",
    });
  },
  getPaises: () => {
    return axios.request({
      url: "/api/paises/",
      method: "GET",
    });
  },
  getDiscapacidades: () => {
    return axios.request({
      url: "/api/discapacidades/",
      method: "GET",
    });
  },
  getEnfermedades: () => {
    return axios.request({
      url: "/api/enfermedades/",
      method: "GET",
    });
  },
  getMetodosEnvio: () => {
    return axios.request({
      url: "/api/configuracion_mensajeria/",
      method: "GET",
    });
  },
  deleteObsNutricionAdjuntos: (obsid: number, adjuntoid: number) => {
    return axios.request({
      url: `/api/obs_nutricion/${obsid}/adjuntos/`,
      method: "DELETE",
      data: {
        id: adjuntoid,
      },
    });
  },
  deletePago: (pagoid: number) => {
    return axios.request({
      url: `/api/socios/pagoProducto/`,
      method: "DELETE",
      data: {
        id: pagoid,
      },
    });
  },
  getObsNutricionAdjuntos: (id: number) => {
    return axios.request({
      url: `/api/obs_nutricion/${id}/adjuntos/`,
      method: "GET",
    });
  },
  addObsNutricionArchivoAdjunto: (
    obsid: number | undefined,
    validatedFormData: any,
    progressCallback: any
  ) => {
    let form_data = new FormData();
    form_data.append("adjunto", validatedFormData, validatedFormData.name);
    return axios.request({
      url: `/api/obs_nutricion/${obsid}/adjuntos/`,
      method: "POST",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  patchObsNutricionbyID: (id: number | undefined, data: any) => {
    return axios.request({
      url: `/api/obs_nutricion/${id}/`,
      method: "PATCH",
      data: {
        observaciones: data.observaciones,
        fecha: data.fecha,
        asunto: data.asunto,
      },
    });
  },
  postObsNutricion: (
    data: any,
    nutricionsocio_id: number | null | undefined
  ) => {
    return axios.request({
      url: "/api/obs_nutricion/",
      method: "POST",
      data: {
        nutricion_socio: nutricionsocio_id,
        observaciones: data.observaciones,
        fecha: data.fecha,
        asunto: data.asunto,
      },
    });
  },
  getObsNutricion: (socio_id: number | undefined) => {
    return axios.request({
      url: `/api/obs_nutricion?socio_id=${socio_id}`,
      method: "GET",
    });
  },
  deleteObsPsicologiaAdjuntos: (obsid: number, adjuntoid: number) => {
    return axios.request({
      url: `/api/obs_psicologia/${obsid}/adjuntos/`,
      method: "DELETE",
      data: {
        id: adjuntoid,
      },
    });
  },
  getObsPsicologiaAdjuntos: (id: number) => {
    return axios.request({
      url: `/api/obs_psicologia/${id}/adjuntos/`,
      method: "GET",
    });
  },
  addObsPsicologiaArchivoAdjunto: (
    obsid: number | undefined,
    validatedFormData: any,
    progressCallback: any
  ) => {
    let form_data = new FormData();
    form_data.append("adjunto", validatedFormData, validatedFormData.name);
    return axios.request({
      url: `/api/obs_psicologia/${obsid}/adjuntos/`,
      method: "POST",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  patchObsPsicologiabyID: (id: number | undefined, data: any) => {
    return axios.request({
      url: `/api/obs_psicologia/${id}/`,
      method: "PATCH",
      data: {
        observaciones: data.observaciones,
        fecha: data.fecha,
        asunto: data.asunto,
      },
    });
  },
  getObsPsicologia: (socio_id: number | undefined) => {
    return axios.request({
      url: `/api/obs_psicologia?socio_id=${socio_id}`,
      method: "GET",
    });
  },
  getEventosPorTipo: (tipo: any) => {
    return axios.request({
      url: `/api/eventos?tipo=${tipo}`,
      method: "GET",
    });
  },
  postObsPsicologia: (
    data: any,
    psicologiasocio_id: number | null | undefined
  ) => {
    return axios.request({
      url: "/api/obs_psicologia/",
      method: "POST",
      data: {
        psicologia_socio: psicologiasocio_id,
        observaciones: data.observaciones,
        fecha: data.fecha,
        asunto: data.asunto,
      },
    });
  },
  getChartData: () => {
    return axios.request({
      url: "/api/charts/",
      method: "GET",
    });
  },
  login: (validatedFormData: LoginFormInput) => {
    return axios.request({
      url: "/api/auth/login/",
      method: "POST",
      data: {
        username: validatedFormData.username,
        password: validatedFormData.password,
      },
      transformResponse: [
        function (data) {
          return UserTransformer(data);
        },
      ],
    });
  },
  logout: () => {
    return axios.request({
      url: "/api/auth/logout/",
      method: "POST",
    });
  },
  verify: () => {
    return axios
      .request({
        url: "/api/auth/user/",
        method: "GET",
      })
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },
  getSocios: () => {
    return axios.request({
      url: "/api/fsocios/",
      method: "GET",
    });
  },
  getSocio: (socioid: string) => {
    return axios.request({
      url: `/api/socios/${socioid}`,
      method: "GET",
    });
  },
  getSocioGrupo: (socioid: string) => {
    return axios.request({
      url: `/api/socios/${socioid}/grupo/`,
      method: "GET",
    });
  },
  getSociosAutocomplete: (query: string) => {
    return axios.request({
      url: `/api/socios/autocomplete/?query=${query}`,
      method: "GET",
    });
  },
  exportDocument: (response: any) => {
    return axios
      .post("/api/descargar_documento/", response, {
        responseType: "blob",
      })
      .then((documentResponse: any) => {
        const blob = new Blob([documentResponse.data], {
          type:
            response.formato === "excel"
              ? "application/ms-excel"
              : "application/pdf",
        });
        const objectUrl = window.URL.createObjectURL(blob);
        let fileLink = document.createElement("a");
        fileLink.href = objectUrl;
        if (response.formato === "excel") fileLink.download = "report.xlsx";
        else fileLink.download = "report.pdf";
        fileLink.click();
      })
      .catch((error: any) => {
        alert(error);
      });
  },
  createSocio: (validatedFormData: SocioFormInput) => {
    return axios
      .request({
        url: "/api/socios/",
        method: "POST",
        data: validatedFormData,
      })
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },
  createMovimiento: (
    validatedFormData: MovimientoFormInput,
    movimientoid: any
  ) => {
    let form_data = new FormData();
    if (
      validatedFormData.adjunto &&
      typeof validatedFormData.adjunto === "object"
    ) {
      form_data.append(
        "adjunto",
        validatedFormData.adjunto,
        validatedFormData.adjunto.name
      );
    }
    form_data.append("tipo", validatedFormData.tipo.toString());
    form_data.append("monto", validatedFormData.monto.toString());
    form_data.append("observaciones", validatedFormData.observaciones);
    form_data.append("categoria", validatedFormData.categoria.toString());
    form_data.append("fecha", validatedFormData.fecha);
    if (validatedFormData.pago) {
      form_data.append("pago", validatedFormData.pago.toString());
    }
    return axios
      .request({
        url:
          movimientoid !== undefined
            ? `/api/movimientos/${movimientoid}/`
            : "/api/movimientos/",
        method: movimientoid !== undefined ? "PATCH" : "POST",
        data: form_data,
      })
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },
  editMovimiento: (validatedFormData: any, movimientoid: any) => {
    let form_data = new FormData();
    if (validatedFormData.adjunto) {
      form_data.append(
        "adjunto",
        validatedFormData.adjunto,
        validatedFormData.adjunto.name
      );
    } else {
      form_data.append("adjunto", "");
    }
    form_data.append("tipo", validatedFormData.tipo.toString());
    form_data.append("monto", validatedFormData.monto.toString());
    form_data.append("observaciones", validatedFormData.observaciones);
    form_data.append("categoria", validatedFormData.categoria.toString());
    form_data.append("fecha", validatedFormData.fecha);
    if (validatedFormData.pago) {
      form_data.append("pago", validatedFormData.pago.toString());
    }
    return axios
      .request({
        url: `/api/socios/${movimientoid}/`,
        method: "PUT",
        data: form_data,
      })
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },
  patchSocio: (
    socioid: number | undefined,
    field: string,
    value: any,
    file = false
  ) => {
    let data = {};
    let headers = "application/json";
    if (file) {
      let form_data = new FormData();
      form_data.append(field, value, value.name);
      headers = "multipart/form-data";
      data = form_data;
    } else {
      data = {
        [field]: value,
      };
    }
    return axios.request({
      url: `/api/socios/${socioid}/`,
      method: "PATCH",
      headers: {
        "Content-Type": headers,
      },
      data: data,
    });
  },
  patchNutricion: (socioid: number | undefined, field: string, value: any) => {
    return axios.request({
      url: `/api/socios/${socioid}/nutricion/`,
      method: "PATCH",
      data: {
        [field]: value,
      },
    });
  },
  getSocioActividades: () => {
    return axios.request({
      url: "/api/actividades/",
      method: "GET",
    });
  },
  getNextTipoEstadoSocio: (tipoEstadoId: number | undefined) => {
    return axios.request({
      url: `/api/tipoestadosocio/?id_actual=${tipoEstadoId}`,
      method: "GET",
    });
  },
  getSocioTipoSocios: () => {
    return axios.request({
      url: "/api/tipos_socio/",
      method: "GET",
    });
  },
  getSocioTipoSocioID: (tipoSocioId: number) => {
    return axios.request({
      url: `/api/tipos_socio/${tipoSocioId}`,
      method: "GET",
    });
  },
  addSocioActividades: (
    socioid: number | undefined,
    validatedFormData: any
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/actividades/`,
      method: "PATCH",
      data: {
        actividad_id: validatedFormData.id,
      },
    });
  },
  addSocioActividadesAdjunto: (
    socioid: number | undefined,
    validatedFormData: any,
    progressCallback: any
  ) => {
    let form_data = new FormData();
    if (validatedFormData.adjunto) {
      form_data.append(
        "adjunto",
        validatedFormData.adjunto,
        validatedFormData.adjunto.name
      );
    } else {
      form_data.append("adjunto", "");
    }
    form_data.append("actividad", validatedFormData.id);
    return axios.request({
      url: `/api/socios/${socioid}/adjunto_actividad/`,
      method: "POST",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  getMovimientos: () => {
    return axios.request({
      url: "/api/movimientos/",
      method: "GET",
    });
  },
  getSocioTipoMetodoPagos: () => {
    return axios.request({
      url: "/api/tipos_metodo_pago/",
      method: "GET",
    });
  },
  getSocioEstadoPagos: () => {
    return axios.request({
      url: "/api/estado_pago/",
      method: "GET",
    });
  },
  getSocioRazonPagos: () => {
    return axios.request({
      url: "/api/razon_pagos/",
      method: "GET",
    });
  },
  getBancos: () => {
    return axios.request({
      url: "/api/bancos/",
      method: "GET",
    });
  },
  getSummaryMovimientos: () => {
    return axios.request({
      url: "/api/summary_movimientos/",
      method: "GET",
    });
  },
  getSocioGeneros: () => {
    return axios.request({
      url: "/api/genero_socio/",
      method: "GET",
    });
  },
  getSocioNutricion: (socioid: number | undefined) => {
    return axios.request({
      url: `/api/socios/${socioid}/nutricion/`,
      method: "GET",
    });
  },
  getSocioDocumentacion: (socioid: number) => {
    return axios.request({
      url: `/api/socios/${socioid}/documentacion/`,
      method: "GET",
    });
  },
  addSocioPago: (
    socioid: number | string | undefined,
    validatedFormData: any
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/pagos/`,
      method: "PATCH",
      data: validatedFormData,
    });
  },
  addSocioDocumentacion: (
    socioid: number | undefined,
    validatedFormData: any,
    progressCallback: any
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/documentacion/`,
      method: "POST",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: validatedFormData,
    });
  },
  getSocioArchivoAdjunto: (socioid: number | undefined) => {
    return axios.request({
      url: `/api/socios/${socioid}/adjunto_actividad/`,
      method: "GET",
      data: {
        socio: socioid,
      },
    });
  },
  deleteSocioArchivoAdjuntos: (socioid: number | undefined, adjuntos: any) => {
    return axios.request({
      url: `/api/socios/${socioid}/documentacion/`,
      method: "DELETE",
      data: {
        adjuntos: adjuntos,
      },
    });
  },

  setNullArchivoSocio: (field: string, socioid: number | undefined) => {
    return axios.request({
      url: `/api/socios/${socioid}/documentacion/`,
      method: "PATCH",
      data: {
        [field]: null,
      },
    });
  },
  setDocumentoSocio: (
    socioid: number | undefined,
    validatedFormData: any,
    progressCallback: any,
    field: string
  ) => {
    let form_data = new FormData();
    form_data.append(field, validatedFormData, validatedFormData.name);
    return axios.request({
      url: `/api/socios/${socioid}/documentacion/`,
      method: "PATCH",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  deleteSocioPago: (socioid: number | undefined, pagos: any) => {
    return axios.request({
      url: `/api/socios/${socioid}/pagos/`,
      method: "DELETE",
      data: {
        pagos: pagos,
      },
    });
  },
  deleteSocioActividad: (socioid: number | undefined, actividades: any) => {
    return axios.request({
      url: `/api/socios/${socioid}/actividades/`,
      method: "DELETE",
      data: {
        actividades: actividades,
      },
    });
  },
  getSociosDisponiblesGrupo: (grupoid: number | undefined) => {
    return axios.request({
      url: `api/socios/?filtrar_grupo=${grupoid}`,
      method: "GET",
    });
  },
  getSocioMetodosPago: (socioid: number | undefined) => {
    return axios.request({
      url: `/api/socios/${socioid}/metodos_pago/`,
      method: "GET",
    });
  },
  addSocioMetodosPago: (
    socioid: number | undefined,
    data: SocioMetodoPagoFormInput
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/metodos_pago/`,
      method: "POST",
      data: data,
    });
  },
  deleteSocioMetodosPagos: (
    socioid: number | undefined,
    metodosdepago: number[]
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/metodos_pago/`,
      method: "DELETE",
      data: {
        metodosdepago: metodosdepago,
      },
    });
  },
  createSocioGrupoTitular: (socioid: number | undefined) => {
    return axios.request({
      url: `/api/grupos/create_solo_lider/`,
      method: "POST",
      data: {
        socio_id: socioid,
      },
    });
  },
  createSocioGrupoConAdjunto: (
    socioid: number | undefined,
    adjuntoid: number | undefined
  ) => {
    return axios.request({
      url: `/api/grupos/create_con_alguien/`,
      method: "POST",
      data: {
        socio_id: socioid,
        adjunto_id: adjuntoid,
      },
    });
  },
  patchSocioGrupoTitular: (
    grupoid: number | undefined,
    socioid: number | undefined
  ) => {
    return axios.request({
      url: `/api/grupos/${grupoid}/update_titular/`,
      method: "PATCH",
      data: {
        socio_id: socioid,
      },
    });
  },
  getSociosResponsablesMayores: () => {
    return axios.request({
      url: `api/socios/?tipo_socio=TODO`,
      method: "GET",
    });
  },
  setSocioMayorResponsable: (
    socioid: number | undefined,
    mayorid: number | undefined
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/responsable/`,
      method: "PATCH",
      data: {
        responsable_mayor: mayorid,
      },
    });
  },
  updateSocioAutorizacion: (
    socioid: number | undefined,
    validatedFormData: SocioAutorizacionFormInput,
    progressCallback: any
  ) => {
    let form_data = new FormData();
    if (validatedFormData.adjunto) {
      form_data.append(
        "adjunto",
        validatedFormData.adjunto,
        validatedFormData.adjunto.name
      );
    } else {
      form_data.append("adjunto", "");
    }
    form_data.append(
      "tipo_autorizacion__codigo",
      validatedFormData.tipo_autorizacion__codigo
    );
    form_data.append("autoriza", validatedFormData.autoriza);
    return axios.request({
      url: `/api/socios/${socioid}/autorizaciones/`,
      method: "PATCH",
      onUploadProgress: progressCallback,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form_data,
    });
  },
  getTipoSuscripciones: () => {
    return axios.request({
      url: "/api/tiposuscripcion/",
      method: "GET",
    });
  },
  getTipoMensaje: () => {
    return axios.request({
      url: "/api/tipomensaje/",
      method: "GET",
    });
  },
  getSocioSuscripciones: (socioid: number) => {
    return axios.request({
      url: `/api/socios/${socioid}/suscripciones/`,
      method: "GET",
    });
  },
  getSuscripcionData: (suscripcion_id: string) => {
    return axios.request({
      url: `api/plansuscripcion/${suscripcion_id}/`,
      method: "GET",
    });
  },
  createSuscripcion: (socioid: number | string, suscripcion_id: string) => {
    console.log("yes");
    return axios.request({
      url: `/api/socios/${socioid}/suscripciones/`,
      method: "POST",
      data: { suscripcion_id },
    });
  },
  createNewSuscripcion: (
    socioNumber: string,
    suscripcion_id: number,
    motivo_pago?: string,
    monto?: number,
    payer_id?: string,
    payer_email?: string
  ) => {
    console.log("createNewSuscripcion");
    return axios.request({
      url: `/api/suscripciones/nueva/`,
      method: "POST",
      data: { suscripcion_id, socio_numero: socioNumber, motivo_pago, monto },
    });
  },
  addSocioSuscripcion: (
    socioid: number | undefined,
    validatedFormData: SocioSuscripcionFormInput
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/suscripciones/`,
      method: "POST",
      data: validatedFormData,
    });
  },
  deleteSocioSuscripciones: (
    socioid: number | undefined,
    suscripciones: number[]
  ) => {
    return axios.request({
      url: `/api/socios/${socioid}/suscripciones/`,
      method: "DELETE",
      data: {
        suscripciones: suscripciones,
      },
    });
  },
  getCategoriaAdjuntos: () => {
    return axios.request({
      url: `/api/categoriaadjuntos/`,
      method: "GET",
    });
  },
  getCategoriaMovimientos: () => {
    return axios.request({
      url: `/api/categoria_movimientos/`,
      method: "GET",
    });
  },
  getPreguntasFrecuentes: () => {
    return axios.request({
      url: `/api/faq/`,
      method: "GET",
    });
  },
  getUserActividades: () => {
    return axios.request({
      url: `/api/actividades/getByUser/`,
      method: "GET",
    });
  },
  getPaymentPreference: (preferenceName: string) => {
    return axios.request({
      url: `/api/payments/?name=${preferenceName}`,
      method: "GET",
    });
  },
  getSuscripcion: () => {
    return axios.request({
      url: `/api/plansuscripcion/`,
      method: "GET",
    });
  },
};
