import { User } from "../models/Usuarios/User";

export const UserTransformer = (data: any) => {
  const result = JSON.parse(data);
  if (result && result.user) {
    return User.create({
      username: result.user.username,
      id: result.user.pk,
      email: result.user.email,
      firstName: result.user.first_name,
      lastName: result.user.last_name,
      avatarImage: "",
      permisos: result.user.permisos,
      language: "ES",
    });
  }
};
