import { types } from "mobx-state-tree";

export const MercadoPago = types.model("MercadoPago", {
  additional_info: types.string,
  auto_return: types.string,
  back_urls: types.model({
    failure: types.string,
    pending: types.string,
    success: types.string,
  }),
  binary_mode: types.boolean,
  client_id: types.string,
  collector_id: types.number,
  coupon_code: types.null,
  coupon_labels: types.null,
  date_created: types.string,
  date_of_expiration: types.null,
  expiration_date_from: types.null,
  expiration_date_to: types.null,
  expires: types.boolean,
  external_reference: types.string,
  id: types.string,
  init_point: types.string,
  internal_metadata: types.null,
  items: types.array(
    types.model({
      id: types.string,
      category_id: types.string,
      currency_id: types.string,
      description: types.string,
      title: types.string,
      quantity: types.number,
      unit_price: types.number,
    })
  ),
  marketplace: types.string,
  marketplace_fee: types.number,
  metadata: types.model({}),
  notification_url: types.string,
  operation_type: types.string,
  payer: types.model({
    phone: types.model({
      area_code: types.string,
      number: types.string,
    }),
    address: types.model({
      zip_code: types.string,
      street_name: types.string,
      street_number: types.null,
    }),
    email: types.string,
    identification: types.model({
      number: types.string,
      type: types.string,
    }),
    name: types.string,
    surname: types.string,
    date_created: types.null,
    last_purchase: types.null,
  }),
  payment_methods: types.model({
    default_card_id: types.null,
    default_payment_method_id: types.null,
    excluded_payment_methods: types.array(
      types.model({
        id: types.string,
      })
    ),
    excluded_payment_types: types.array(
      types.model({
        id: types.string,
      })
    ),
    installments: types.null,
    default_installments: types.null,
  }),
  processing_modes: types.null,
  product_id: types.null,
  redirect_urls: types.model({
    failure: types.string,
    pending: types.string,
    success: types.string,
  }),
  sandbox_init_point: types.string,
  site_id: types.string,
  shipments: types.model({
    default_shipping_method: types.null,
    receiver_address: types.model({
      zip_code: types.string,
      street_name: types.string,
      street_number: types.null,
      floor: types.string,
      apartment: types.string,
      city_name: types.null,
      state_name: types.null,
      country_name: types.null,
    }),
  }),
  total_amount: types.null,
  last_updated: types.null,
});
