import React from "react";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { getFromLS, setToLS } from "./utils/storage";

import { useCreateStore, useProvider } from "mobx-store-provider";
import { onSnapshot } from "mobx-state-tree";
import RootStore from "./stores/RootStore";
import VolatileStore from "./stores/VolatileStore";
import { observer } from "mobx-react";

import theme, { profilePanelTableThemeOverride } from "./theme";
import { ThemeProvider } from "@material-ui/core";

import { Router } from "@reach/router";
import { api } from "./api";

const LoginPagePage = loadable(() => import("./pages/LoginPage/LoginPagePage"));

const MisActividadesPage = loadable(
  () => import("./pages/MisActividadesPage/MisActividadesPage")
);
const MiActividadPage = loadable(
  () => import("./pages/MiActividadPage/MiActividadPage")
);
const PagosPage = loadable(() => import("./pages/PagosPage/PagosPage"));
const PagoPage = loadable(() => import("./pages/PagoPage/PagoPage"));

const ThankYouPage = loadable(
  () => import("./pages/ThankYouPage/ThankYouPage")
);
/*const LandingPagosPage = loadable(
  () => import("./pages/LandingPagosPage/LandingPagosPage")
);*/
const NewLandingPagosPage = loadable(
  () => import("./pages/LandingPagosPage/NewLandingPagosPage")
);
const TestPage = loadable(() => import("./pages/TestPage/TestPage"));
const AuthenticatedRoute = loadable(
  () => import("./components/AuthenticatedRoute/AuthenticatedRoute")
);
const SocioAddPage = loadable(
  () => import("./pages/SocioAddPage/SocioAddPage")
);
const MovimientoAddPage = loadable(
  () => import("./pages/MovimientoAddPage/MovimientoAddPage")
);
const MovimientoEditPage = loadable(
  () => import("./pages/MovimientoEditPage/MovimientoEditPage")
);
//const LoginPage = loadable(() => import("./pages/LoginPage/LoginPage"));
const SocioListPage = loadable(
  () => import("./pages/SocioListPage/SocioListPage")
);
const MovimientoListPage = loadable(
  () => import("./pages/MovimientoListPage/MovimientoListPage")
);
const ActividadListPage = loadable(
  () => import("./pages/ActividadListPage/ActividadListPage")
);
const HomePage = loadable(() => import("./pages/HomePage/HomePage"));
const MovimientoSummaryPage = loadable(
  () => import("./pages/MovimientoSummaryPage/MovimientoSummaryPage")
);
const ActividadProfilePage = loadable(
  () => import("./pages/ActividadProfilePage/ActividadProfilePage")
);

const ActividadAddPage = loadable(
  () => import("./pages/ActividadAddPage/ActividadAddPage")
);

const SocioProfilePage = loadable(
  () => import("./pages/SocioProfilePage/SocioProfilePage")
);

const CalendarPage = loadable(
  () => import("./pages/CalendarPage/CalendarPage")
);

//const FAQPage = loadable(() => import("./pages/FAQPage/FAQPage"));

const FAQPage = loadable(() => import("./pages/FAQPage/NewFAQPage"));

const PerfilPage = loadable(() => import("./pages/PerfilPage/PerfilPage"));

const FacturaPage = loadable(() => import("./pages/FacturaPage/FacturaPage"));

const Error401 = loadable(() => import("./pages/Error/401"));

const Error404 = loadable(() => import("./pages/Error/404"));

const Error500 = loadable(() => import("./pages/Error/500"));

const Mantenimiento = loadable(() => import("./pages/Error/Mantenimiento"));

const SubscripcionPage = loadable(
  () => import("./pages/SubscripcionPage/SubscripcionPage")
);

const rootStore = getFromLS(process.env.REACT_APP_CLUBMANAGER_STORE_NAME!);

function App() {
  const { t } = useTranslation();
  const store = useCreateStore(RootStore, rootStore ? rootStore : {});
  const StoreProvider = useProvider(RootStore);
  const volatileStore = useCreateStore(VolatileStore, {});
  const VolatileStoreProvider = useProvider(VolatileStore);
  const [loading, setLoading] = React.useState(true);

  const setPreferencias = () => {
    api.getPalette().then((result) => {
      const palette = result.data;
      theme.palette.primary.light = palette.primary.light;
      theme.palette.primary.main = palette.primary.main;
      theme.palette.primary.dark = palette.primary.dark;
      theme.palette.secondary.light = palette.secondary.light;
      theme.palette.secondary.main = palette.secondary.main;
      theme.palette.secondary.dark = palette.secondary.dark;
    });
  };

  onSnapshot(store, (newSnapshot) => {
    setToLS(process.env.REACT_APP_CLUBMANAGER_STORE_NAME!, newSnapshot);
  });
  if (!rootStore) {
    setToLS(process.env.REACT_APP_CLUBMANAGER_STORE_NAME!, store);
    setPreferencias();
  }

  React.useEffect(() => {
    volatileStore.verify().then(() => {
      volatileStore.getMPToken().then(() => {
        setLoading(false);
      });
      setPreferencias();
    });
  }, [volatileStore]);

  if (loading) {
    return null;
  }
  return (
    <div className="App">
      <Helmet>
        <title>
          {process.env.REACT_APP_CLUB_NAME} | {t("Club Manager")}
        </title>
      </Helmet>
      <ThemeProvider theme={profilePanelTableThemeOverride}>
      <ThemeProvider theme={theme}>
        <VolatileStoreProvider value={volatileStore}>
          <StoreProvider value={store}>
            <Router>
              <AuthenticatedRoute as={HomePage} path="/" />
              <AuthenticatedRoute as={SocioListPage} path="/socios" />
              <AuthenticatedRoute as={MovimientoListPage} path="/movimientos" />
              <AuthenticatedRoute as={ActividadListPage} path="/actividades" />
              <AuthenticatedRoute as={PagosPage} path="/pagos" />
              <AuthenticatedRoute as={SocioAddPage} path="/socios/nuevo" />
              <AuthenticatedRoute as={FAQPage} path="/faq" />
              <AuthenticatedRoute as={PerfilPage} path="/account-settings" />
              <AuthenticatedRoute as={FacturaPage} path="/factura" />
              <AuthenticatedRoute
                as={CalendarPage}
                path="/actividades/calendario"
                extraProps={{
                  helmet: "Calendario de Actividades",
                  modelo: "Actividades",
                }}
              />
              <AuthenticatedRoute
                as={CalendarPage}
                path="/pagos/calendario"
                extraProps={{ helmet: "Calendario de Pagos", modelo: "Pagos" }}
              />
              <AuthenticatedRoute
                as={CalendarPage}
                path="/socios/calendario"
                extraProps={{
                  helmet: "Calendario de Socios",
                  modelo: "Socios",
                }}
              />
              <AuthenticatedRoute
                as={CalendarPage}
                path="/movimientos/calendario"
                extraProps={{
                  helmet: "Calendario de Movimientos",
                  modelo: "Movimientos",
                }}
              />
              <AuthenticatedRoute
                as={MisActividadesPage}
                path="/misactividades"
              />
              <AuthenticatedRoute
                as={MiActividadPage}
                path="/miactividad/:actividadid"
              />
              <AuthenticatedRoute
                as={ActividadProfilePage}
                path="/actividad/:actividadid"
              />
              <AuthenticatedRoute
                as={ActividadAddPage}
                path="/actividades/nuevo"
              />
              <AuthenticatedRoute
                as={MovimientoSummaryPage}
                path="/movimientos/summary"
              />
              <AuthenticatedRoute
                as={MovimientoAddPage}
                path="/movimientos/nuevo"
              />
              <AuthenticatedRoute
                as={MovimientoEditPage}
                path="/movimiento/:movimientoid"
              />
              <AuthenticatedRoute
                as={SocioProfilePage}
                path="/socio/:socioid"
              />
              {/* <AuthenticatedRoute as={LoginPagePage} path="/newlogin" /> */}
              {/* <LoginPage path="/login" /> */}
              <LoginPagePage path="/login" />
              <Error401 path="/error-401" />
              <Error404 path="/error-404" />
              <Error500 path="/error-500" />
              <Mantenimiento path="/mantenimiento" />
              <TestPage path="/test" />
              <PagoPage path="/pago/:pagoid" />
              <ThankYouPage path="/thankyou/:tipopago/:pagoid/:estadopago" />
              <ThankYouPage path="/thankyou/" />
              {["/contratar/:productoid", "/contratar/", "/contratar"].map(
                (page) => (
                  // <LandingPagosPage path={page} key={page} />
                  <NewLandingPagosPage path={page} key={page} />
                )
              )}
              {["/subscripciones/:productoid", "/subscripciones/", "/subscripciones"].map(
                (page) => (
                  <SubscripcionPage path={page} key={page} />
                )
              )}
            </Router>
          </StoreProvider>
        </VolatileStoreProvider>
      </ThemeProvider>
      </ThemeProvider>
    </div>
  );
}

export default observer(App);
