import { types } from "mobx-state-tree";
import { TipoMetodoPago } from "./TipoMetodoPago";
import { RazonPago } from "./RazonPago";
import { EstadoPago } from "./EstadoPago";
import { MercadoPago } from "./MercadoPago";

export const PagoSocio = types.model("PagoSocio", {
  id: 0,
  id_pago: types.union(types.string, types.number),
  id_pago_ml: types.maybeNull(types.union(types.string, types.number)),
  monto: types.union(types.string, types.number),
  razon: types.maybeNull(RazonPago),
  tipo_metodo_pago: types.maybeNull(TipoMetodoPago),
  fecha_pago: "",
  adjunto: types.maybeNull(types.string),
  observaciones: "",
  razon_otro: "",
  estado: types.maybeNull(EstadoPago),
  creado_en: "",
  datos_proveedor_pagos: types.maybe(types.union(MercadoPago, types.model({}))),
});
