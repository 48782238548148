import { types } from "mobx-state-tree";
import { Enfermedad } from "./Enfermedad";

export const Nutricion = types.model("Nutrición", {
  id: 0,
  altura: "",
  peso: "",
  imc: "",
  circunferencia_cintura: "",
  cadera: "",
  colesterol_hdl: "",
  colesterol_ldl: "",
  colesterol_total: "",
  creatinina: "",
  glucemia: "",
  hemoglobina: "",
  hepatograma_gdp: "",
  hepatograma_got: "",
  insulina_plasmatica: "",
  t4l: "",
  trigliceridos: "",
  tsh: "",
  uremia: "",
  uricemia: "",
  historia_clinica: "",
  enfermedades: types.array(Enfermedad),
});
