import { flow, types } from "mobx-state-tree";
import { api } from "../../api";
import { ActividadSocio } from "../Actividades/ActividadSocio";

export const User = types
  .model("User", {
    username: "",
    id: 0,
    email: "",
    firstName: "",
    lastName: "", 
    avatarImage: "",
    language: "ES",
    permisos: "",
    actividades: types.array(ActividadSocio),
  })
  .actions((self) => {
    return {
      tiene_permiso: (permiso: string) => {
        if (self.permisos.indexOf(permiso) >= 0) return true;
        else return false;
      },
      getActividades: flow(function* () {
        try {
          const result = yield api.getUserActividades();
          self.actividades = result.data;
          return true;
        } catch (error) {
          console.error("Failed", error);
        }
      }),
    };
  });
