const PaletteTransformer = (data: any) => {
  const result = JSON.parse(data);
  const palette = {
    primary: {
      light: result[1].value,
      main: result[0].value,
      dark: result[2].value,
    },
    secondary: {
      light: result[4].value,
      main: result[3].value,
      dark: result[5].value,
    },
  };
  return palette;
};
export default PaletteTransformer;
